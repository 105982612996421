exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/josephhardwick/Documents/repos/petBlog/pet-blog/blog/my-first-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-second-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/josephhardwick/Documents/repos/petBlog/pet-blog/blog/second/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-second-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipe-js": () => import("./../../../src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-recipe-template-js": () => import("./../../../src/templates/recipe-template.js" /* webpackChunkName: "component---src-templates-recipe-template-js" */)
}

